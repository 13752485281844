import type * as Types from '~/generated/graphql';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type AvailableShowsVariables = Types.Exact<{
  currentNetworkId: Types.Scalars['Int']['input'];
}>;


export type AvailableShows = { readonly __typename: 'Query', readonly network?: { readonly __typename: 'Network', readonly id: number, readonly shows: { readonly __typename: 'ShowPage', readonly nodes: ReadonlyArray<{ readonly __typename: 'Show', readonly id: number, readonly name: string }> } } | null };

export type Device__AvailableShow = { readonly __typename: 'Show', readonly id: number, readonly name: string };

export const Device__AvailableShow = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"Device__AvailableShow"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Show"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}}]} as unknown as DocumentNode<Device__AvailableShow, unknown>;
export const AvailableShowsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"AvailableShows"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"currentNetworkId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"network"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"currentNetworkId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"shows"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"nodes"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"Device__AvailableShow"}}]}}]}}]}}]}},{"kind":"FragmentDefinition","name":{"kind":"Name","value":"Device__AvailableShow"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Show"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}}]} as unknown as DocumentNode<AvailableShows, AvailableShowsVariables>;