import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { z } from 'zod';
import { type MultiChipItem } from '~/components/inputs';
import {
  DeviceKind,
  DeviceStatus,
  type Deprecated__DeviceList__DeviceGroupFragment as DeviceGroup,
} from '~/generated/graphql';

const INITIAL_ARCHS = [
  { label: 'arm', selected: false, value: 'arm' },
  { label: 'arm64', selected: false, value: 'arm64' },
  { label: 'x64', selected: false, value: 'x64' },
];

export const INITIAL_KINDS = [
  { label: 'Android', selected: false, value: DeviceKind.Android },
  { label: 'Brightsign', selected: false, value: DeviceKind.Brightsign },
  { label: 'Linux', selected: false, value: DeviceKind.Linux },
  { label: 'Samsung', selected: false, value: DeviceKind.Samsung },
  { label: 'webOS', selected: false, value: DeviceKind.Webos },
];

export const INITIAL_STATUS = [
  { label: 'Connected', selected: false, value: DeviceStatus.Healthy },
  { label: 'Offline', selected: false, value: DeviceStatus.Error },
  { label: 'Warning', selected: false, value: DeviceStatus.Warn },
];

export const INITIAL_INTERNAL = [
  { label: 'True', selected: false, value: true },
  { label: 'False', selected: false, value: false },
];

export const INITIAL_DEMO = [
  { label: 'True', selected: false, value: true },
  { label: 'False', selected: false, value: false },
];

const ARCH_SCHEMA = z.array(z.string());
const DEMO_SCHEMA = z.array(z.boolean());
const GROUP_SCHEMA = z.array(z.number());
const INTERNAL_SCHEMA = z.array(z.boolean());
const KIND_SCHEMA = z.array(
  z.enum([
    DeviceKind.Android,
    DeviceKind.Brightsign,
    DeviceKind.Linux,
    DeviceKind.Samsung,
    DeviceKind.Webos,
  ]),
);
const STATUS_SCHEMA = z.array(
  z.enum([DeviceStatus.Healthy, DeviceStatus.Error, DeviceStatus.Warn]),
);
const TAG_SCHEMA = z.array(z.string());

export const useFilterParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  return useMemo(() => {
    try {
      const archsParams = ARCH_SCHEMA.parse(JSON.parse(searchParams.get('archs') || '[]'));
      const demoParams = DEMO_SCHEMA.parse(JSON.parse(searchParams.get('demo') || '[]'));
      const groupsParams = GROUP_SCHEMA.parse(JSON.parse(searchParams.get('groups') || '[]'));
      const internalParams = INTERNAL_SCHEMA.parse(
        JSON.parse(searchParams.get('internal') || '[]'),
      );
      const kindsParams = KIND_SCHEMA.parse(JSON.parse(searchParams.get('kinds') || '[]'));
      const statusesParams = STATUS_SCHEMA.parse(JSON.parse(searchParams.get('statuses') || '[]'));
      const tagsParams = TAG_SCHEMA.parse(JSON.parse(searchParams.get('tags') || '[]'));

      return {
        archsParams,
        demoParams,
        groupsParams,
        internalParams,
        kindsParams,
        setSearchParams,
        statusesParams,
        tagsParams,
      };
    } catch (e) {
      setTimeout(() => {
        setSearchParams((params) => {
          params.delete('archs');
          params.delete('demo');
          params.delete('groups');
          params.delete('internal');
          params.delete('kinds');
          params.delete('statuses');
          params.delete('tags');
          return params;
        });
      }, 100);
      return {
        archsParams: [],
        demoParams: [],
        groupsParams: [],
        kindsParams: [],
        internalParams: [],
        setSearchParams,
        statusesParams: [],
        tagsParams: [],
      };
    }
  }, [searchParams, setSearchParams]);
};

export const useFilters = (deviceTags: readonly string[], deviceGroups: readonly DeviceGroup[]) => {
  const {
    archsParams,
    demoParams,
    groupsParams,
    internalParams,
    kindsParams,
    setSearchParams,
    statusesParams,
    tagsParams,
  } = useFilterParams();

  const [archs, setArchs] = useState(
    INITIAL_ARCHS.map((x) => ({ ...x, selected: archsParams.includes(x.value) })),
  );
  const [demo, setDemo] = useState(
    INITIAL_DEMO.map((x) => ({ ...x, selected: demoParams.includes(x.value) })),
  );
  const [groups, setGroups] = useState<MultiChipItem<number>[]>([]);
  const [internal, setInternal] = useState(
    INITIAL_INTERNAL.map((x) => ({ ...x, selected: internalParams.includes(x.value) })),
  );
  const [kinds, setKinds] = useState(
    INITIAL_KINDS.map((x) => ({ ...x, selected: kindsParams.includes(x.value) })),
  );
  const [statuses, setStatuses] = useState(
    INITIAL_STATUS.map((x) => ({ ...x, selected: statusesParams.includes(x.value) })),
  );
  const [tags, setTags] = useState<MultiChipItem<string>[]>([]);

  useEffect(() => {
    if (deviceTags.join() === tags.map((x) => x.value).join()) return;
    setTags(
      deviceTags.map((x) => ({
        label: x,
        selected: tagsParams.includes(x),
        value: x,
      })),
    );
  }, [deviceTags, tags, tagsParams]);

  useEffect(() => {
    if (deviceGroups.map((x) => x.id).join() === groups.map((x) => x.value).join()) return;
    setGroups([
      ...deviceGroups.map((x) => ({
        label: x.name,
        selected: groupsParams.includes(x.id),
        value: x.id,
      })),
    ]);
  }, [deviceGroups, groups, groupsParams]);

  const handleClear = useCallback(() => {
    setArchs(INITIAL_ARCHS);
    setDemo(INITIAL_DEMO);
    setGroups((current) => current.map((x) => ({ ...x, selected: false })));
    setInternal(INITIAL_INTERNAL);
    setKinds(INITIAL_KINDS);
    setStatuses(INITIAL_STATUS);
    setTags((current) => current.map((x) => ({ ...x, selected: false })));
    setSearchParams((params) => {
      params.delete('archs');
      params.delete('demo');
      params.delete('groups');
      params.delete('internal');
      params.delete('kinds');
      params.delete('statuses');
      params.delete('tags');
      params.set('page', '1');
      return params;
    });
  }, [setSearchParams]);

  const updateSearchParams = useCallback(
    (
      name: string,
      state: MultiChipItem<DeviceKind | DeviceStatus | number | string | boolean>[],
    ) => {
      setSearchParams((params) => {
        const selected = state.filter((x) => x.selected);
        selected.length > 0
          ? params.set(name, JSON.stringify(selected.map((x) => x.value)))
          : params.delete(name);
        params.set('page', '1'); // reset pagination when filters change
        return params;
      });
    },
    [setSearchParams],
  );

  return {
    archs,
    demo,
    groups,
    handleClear,
    internal,
    kinds,
    setArchs,
    setDemo,
    setGroups,
    setInternal,
    setKinds,
    setStatuses,
    setTags,
    statuses,
    tags,
    updateSearchParams,
  };
};
