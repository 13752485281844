import { FeaturedPlayList, MoreVert, PlayArrow, Settings, SwapHoriz } from '@mui/icons-material';
import {
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Menu as MuiMenu,
  Tooltip,
} from '@mui/material';
import { type ElementType } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useDeleteDevice } from '~/api/devices';
import { ActionButton } from '~/components/button';
import { TransferDialog, useTransferDeviceDialog } from '~/components/devices/transfer-dialog';
import { ConfirmDialog } from '~/components/dialogs/confirmation';
import { MoreAction, MoreActionLink, MoreDeleteAction } from '~/components/table';
import { useAppContext } from '~/contexts';
import { useConfirmDialog } from '~/hooks/dialogs';
import { useMoreActions } from '~/hooks/table';
import { assert } from '~/lib/assert';
import { type DeviceList__Device as Device } from '../../queries/list.generated';

export interface MenuProps {
  buttonType?: 'action' | 'icon';
  device: Device;
  Icon?: ElementType;
}

export const Menu = ({ buttonType = 'icon', device, Icon = MoreVert }: MenuProps) => {
  const { currentUser } = useAppContext();
  const location = useLocation();
  const [moreMenuProps, moreActionProps, moreTableActionProps] = useMoreActions<Device>();
  const deviceActionProps = moreTableActionProps(device);
  const [openTransferDialog, transferDialogProps] = useTransferDeviceDialog();
  const [confirmDelete, confirmDeleteProps] = useConfirmDialog();
  const [deleteDevice] = useDeleteDevice();

  return (
    <>
      {buttonType === 'icon' ? (
        <Tooltip title="Actions" arrow>
          <IconButton
            color="secondary"
            aria-label="actions"
            onClick={deviceActionProps.onClick}
            size="small"
          >
            <Icon />
          </IconButton>
        </Tooltip>
      ) : (
        <ActionButton {...deviceActionProps} />
      )}
      <MuiMenu {...moreMenuProps}>
        <MoreActionLink
          Icon={FeaturedPlayList}
          onClick={moreActionProps.closeMenu}
          title="Manifest"
          target="_blank"
          to={`/devices/${moreMenuProps.context?.id}/manifest.json`}
        />
        <MenuItem
          component={Link}
          to={{ pathname: `${moreMenuProps.context?.id}/preview`, search: location.search }}
          state={true}
        >
          <ListItemIcon>
            <PlayArrow />
          </ListItemIcon>
          <ListItemText>Preview</ListItemText>
        </MenuItem>

        <MenuItem
          component={Link}
          to={{ pathname: `${moreMenuProps.context?.id}/settings`, search: location.search }}
          state={true}
        >
          <ListItemIcon>
            <Settings />
          </ListItemIcon>
          <ListItemText>Settings</ListItemText>
        </MenuItem>

        <MoreAction
          {...moreActionProps}
          Icon={SwapHoriz}
          title="Transfer"
          onClick={() => {
            assert(moreMenuProps.context != null, 'transfer: no device context');
            openTransferDialog(moreMenuProps.context);
          }}
        />

        {currentUser.admin && (
          <span>
            <Divider />
            <MoreDeleteAction
              {...moreActionProps}
              onClick={async () => {
                assert(moreMenuProps.context != null, 'delete: no device context');
                if (!(await confirmDelete())) return;
                await deleteDevice({
                  variables: { deviceId: moreMenuProps.context.id },
                });
                moreMenuProps.onClose();
              }}
            />
          </span>
        )}
      </MuiMenu>
      <TransferDialog {...transferDialogProps} />
      <ConfirmDialog
        {...confirmDeleteProps}
        confirm="Permanently Delete"
        deleteConfirm
        prompt={
          <span>
            Deleting this device will permanently remove it from the system.{' '}
            <b>This operation may not be undone.</b>
          </span>
        }
        title="Delete Device"
      />
    </>
  );
};
