import { Grid } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  card: {
    background: '#ffffff',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(0, 0),
    border: '1px solid #e6e6e6',
    borderRadius: '4px',
    '& .MuiGrid-item': {
      padding: theme.spacing(2, 0),
      margin: theme.spacing(0, 2),
    },
  },
  detail: {},
  count: {
    color: theme.palette.primary.main,
    fontSize: '60px',
    fontWeight: 700,
    lineHeight: '60px',
    textAlign: 'center',
  },
  title: {
    textAlign: 'center',
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '28px',
    color: '#000000',
  },
}));

interface DeviceStatProps {
  count: number | undefined | null;
  title: string;
}

const DeviceStat = (props: DeviceStatProps) => {
  const { count, title } = props;

  const classes = useStyles();

  return (
    <Grid item>
      <div className={classes.detail}>
        <div className={classes.count}>{count}</div>
        <div className={classes.title}>{title}</div>
      </div>
    </Grid>
  );
};

export interface DeviceStatsProps {
  activeCount: number;
  errorCount: number;
  healthyCount: number;
  warnCount: number;
}

export const DeviceStats = (props: DeviceStatsProps) => {
  const { activeCount, errorCount, healthyCount, warnCount } = props;

  const classes = useStyles();

  const deviceStateTitle = (count: number, title: string) => (count > 1 ? `${title}s` : title);

  return (
    <Grid container className={classes.card}>
      <DeviceStat count={activeCount} title={deviceStateTitle(activeCount, 'Activated Device')} />
      <DeviceStat count={healthyCount} title={deviceStateTitle(1, 'Healthy Device')} />
      <DeviceStat count={warnCount} title={deviceStateTitle(warnCount, 'Warn Device')} />
      <DeviceStat count={errorCount} title={deviceStateTitle(errorCount, 'Error Device')} />
    </Grid>
  );
};
