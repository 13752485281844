import { Check, Info, Warning } from '@mui/icons-material';
import { Chip, MenuItem, Select, Tooltip } from '@mui/material';
import { type GridColDef, type GridRenderCellParams } from '@mui/x-data-grid-pro';
import { useMemo } from 'react';
import { ReadOnlyDataGrid } from '~/components/data-grid';
import { EmptyState } from '~/components/empty-state';
import { Link } from '~/components/link';
import { AuthorizedLink } from '~/components/links';
import { useLink } from '~/hooks/link';
import ActivateDeviceIllustration from '~/images/illustrations/activate-device.svg';
import type {
  NetworkEventTypeShows__DeviceGroup as DeviceGroup,
  NetworkEventTypeShows__EventType as EventType,
  NetworkEventTypeShows__Show as Show,
} from '../../queries/shows.generated';

const NoRowsOverlay = () => (
  <EmptyState
    illustration={ActivateDeviceIllustration}
    description="This network currently does not have any device groups."
    header="Add Device Groups"
  />
);

export interface DeviceGroupColumnProps {
  getValue: (id: number) => number;
  eventType: EventType;
  setValue: (id: number, value: number) => void;
  shows: readonly Show[];
}

const ShowStatus = ({
  deviceGroup,
  eventType,
  showId,
}: {
  deviceGroup: DeviceGroup;
  eventType: EventType;
  showId: number;
}) => {
  const defaultShow = deviceGroup.defaultShow;

  const title =
    showId !== -1
      ? `The selected show will play for this device group during ${eventType.name} events.`
      : defaultShow
      ? `The default show for this device group, ${defaultShow.name}, will play during ${eventType.name} events since no show has been selected to play for this device group.`
      : `No show will play for this device group during ${eventType.name} events.`;

  const icon =
    showId !== -1 ? (
      <Check className="icon" color="success" fontSize="medium" />
    ) : defaultShow ? (
      <Info className="icon" color="info" fontSize="medium" />
    ) : (
      <Warning className="icon" color="warning" fontSize="medium" />
    );

  return <Tooltip title={title}>{icon}</Tooltip>;
};

const useColumns = ({
  getValue,
  eventType,
  setValue,
  shows,
}: DeviceGroupColumnProps): GridColDef[] => {
  const link = useLink();

  return useMemo(
    () => [
      {
        field: 'name',
        headerName: 'Device Group',
        renderCell: ({ row }: GridRenderCellParams<DeviceGroup>) => (
          <AuthorizedLink authorized={row.canUpdate.value} entity={row} />
        ),
        flex: 1,
      },
      {
        field: 'deviceCount',
        headerName: 'Devices',
        headerAlign: 'center',
        align: 'center',
        renderCell: ({ row }: GridRenderCellParams<DeviceGroup>) => {
          return (
            <Chip
              color="primary"
              component={Link}
              label={row.deviceCount || 0}
              onClick={(event) => event.stopPropagation()}
              to={link(`/settings/device-groups/${row.id}/devices`)}
            />
          );
        },
        minWidth: 250,
      },
      {
        field: 'status',
        headerName: '',
        renderCell: ({ row }: GridRenderCellParams<DeviceGroup>) => {
          return <ShowStatus deviceGroup={row} eventType={eventType} showId={getValue(row.id)} />;
        },
        maxWidth: 50,
      },
      {
        field: 'id',
        headerName: 'Assigned Show',
        flex: 0.5,
        renderCell: ({ row }: GridRenderCellParams<DeviceGroup>) => {
          return (
            <Select
              sx={{ flexBasis: '100%' }}
              size="small"
              disabled={!shows}
              value={getValue(row.id)}
              onChange={(event) => setValue(row.id, +event.target.value)}
            >
              <MenuItem value={-1}>-- Select Show --</MenuItem>
              {shows.map(({ id, name }) => (
                <MenuItem key={id} value={id}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          );
        },
      },
    ],
    [getValue, eventType, link, setValue, shows],
  );
};

export interface DeviceGroupTableProps {
  deviceGroups: readonly DeviceGroup[];
  eventType: EventType;
  getValue: (id: number) => number;
  loading: boolean;
  setValue: (id: number, value: number) => void;
  shows: readonly Show[];
}

export const DeviceGroupTable = ({
  deviceGroups,
  eventType,
  getValue,
  loading,
  setValue,
  shows,
}: DeviceGroupTableProps) => {
  const columns = useColumns({ getValue, eventType, setValue, shows });
  return (
    <ReadOnlyDataGrid
      columns={columns}
      loading={loading}
      disableRowSelectionOnClick
      slots={{
        columnResizeIcon: () => null,
        noRowsOverlay: NoRowsOverlay,
      }}
      rows={deviceGroups}
    />
  );
};
