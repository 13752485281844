import type { DeviceKind, DeviceStatus } from '~/generated/graphql';
import { INITIAL_DEMO, INITIAL_INTERNAL, INITIAL_KINDS, INITIAL_STATUS } from './filters';

type FilterKind = Exclude<DeviceKind, 'WEB'>;

/**
 * Helper to generate a scheduled show name from specified device filters.
 *
 * Examples:
 *
 * Tags: Lexus Lounge, Even
 * Search: Lexus
 * Groups: Concessions
 * Status: Connected
 * Kind: Android
 *
 * If multiple filter options are used, they will be separated by --
 */
export const generateScheduledShowName = (
  availableDeviceGroups: { id: number; name: string }[],
  demo: boolean[],
  deviceGroups: number[],
  internal: boolean[],
  kind: FilterKind[],
  search: string,
  status: DeviceStatus[],
  tags: string[],
) => {
  const generateString = (label: string, values: string[]) => {
    const formattedValues = values.filter((value) => value !== '').join(', ');
    return formattedValues !== '' ? `${label}: "${formattedValues}"` : '';
  };

  return [
    generateString(
      'Kind',
      kind.map((value) => (INITIAL_KINDS.find((kind) => kind.value === value) || {}).label ?? ''),
    ),
    generateString('Search', [search]),
    generateString(
      'Groups',
      deviceGroups.map(
        (value) => (availableDeviceGroups.find((group) => group.id === value) || {}).name ?? '',
      ),
    ),
    generateString(
      'Status',
      status.map(
        (value) => (INITIAL_STATUS.find((status) => status.value === value) || {}).label ?? '',
      ),
    ),
    generateString('Tags', tags),
    generateString(
      'Internal',
      internal.map(
        (value) =>
          (INITIAL_INTERNAL.find((internal) => internal.value === value) || {}).label ?? '',
      ),
    ),
    generateString(
      'Demo',
      demo.map((value) => (INITIAL_DEMO.find((demo) => demo.value === value) || {}).label ?? ''),
    ),
  ]
    .filter(Boolean)
    .join(' -- ');
};
