import { Android } from '@mui/icons-material';
import { Checkbox, Grid, Paper, styled, Tooltip, Typography, useTheme } from '@mui/material';
import { type FormikProps } from 'formik';
import { DeviceStatus } from '~/components/devices';
import { DeviceKind } from '~/generated/graphql';
import brightsign from '~/images/brightsign.svg';
import linux from '~/images/linux.svg';
import samsung from '~/images/samsung.png';
import webos from '~/images/webos.png';
import { type DevicesTable__Device as Device } from './devices-table.generated';

const Root = styled(Paper)(({ theme }) => ({
  textAlign: 'center',
  display: 'flex',
  alignItems: 'center',
  margin: '1px 1px 10px',
  color: theme.palette.text.secondary,

  '& > .MuiGrid-container': {
    alignItems: 'center',
    borderRadius: 4,
    borderStyle: 'solid',
    borderWidth: 2,
    boxShadow: 'rgba(0, 0, 0, 0.1) 0 1px 2px 0',
    justifyContent: 'flex-start',
    padding: 5,
    transition: 'all 0.1s ease-out 0s',
    '&:hover': {
      boxShadow: 'rgba(0, 0, 0, 0.1) 0 4px 8px 0',
    },
  },

  '& .linux': {
    height: '1.6em',
  },
  '& .brightsign': {
    height: '1em',
  },

  '& .status': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& > span': {
      fontSize: '1rem',
    },
  },

  '& .MuiCheckbox-root svg': {
    fontSize: 23,
    color: theme.palette.secondary.dark,
  },
}));

/* GraphQL */ `#graphql
fragment DevicesTable__Device on Device {
  active
  activationChangedAt
  id
  kind
  name
  timeSinceUpdate {
    ...Interval
  }
}
`;

interface DevicesTableProps {
  device: Device;
  formik: FormikProps<{ deviceIds: string[] }>;
  multiselect: boolean;
}

export const DevicesTable = ({ device, formik, multiselect }: DevicesTableProps) => {
  const theme = useTheme();

  const deviceLogo =
    device.kind === DeviceKind.Android ? (
      <Android />
    ) : device.kind === DeviceKind.Linux ? (
      <img className="linux" src={linux} />
    ) : device.kind === DeviceKind.Brightsign ? (
      <img className="brightsign" src={brightsign} />
    ) : device.kind === DeviceKind.Webos ? (
      <img className="webos" src={webos} />
    ) : device.kind === DeviceKind.Samsung ? (
      <img className="samsung" src={samsung} />
    ) : null;

  const checked = formik.values.deviceIds.includes(device.id.toString());

  return (
    <Root elevation={1} key={device.id}>
      <Grid container sx={{ borderColor: checked ? theme.palette.primary.main : 'transparent' }}>
        <Grid container item xs={10} display="flex" alignItems="center">
          <Grid container item xs={8} display="flex" alignItems="center">
            <Grid item xs={2} display="flex" alignItems="center" justifyContent="center">
              {deviceLogo}
            </Grid>
            <Grid item xs={3}>
              <DeviceStatus device={device} />
            </Grid>
            <Grid item xs={6} textAlign="start">
              <Tooltip arrow title={device.name} placement="left">
                <Typography>{device.name}</Typography>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={2} textAlign="end">
          <Checkbox
            aria-label={device.name}
            checked={checked}
            color="secondary"
            name="deviceIds"
            onBlur={formik.handleBlur}
            onChange={
              multiselect
                ? formik.handleChange
                : (_, checked) =>
                    formik.setValues({ deviceIds: checked ? [String(device.id)] : [] })
            }
            value={device.id}
          />
        </Grid>
      </Grid>
    </Root>
  );
};
