import { useQuery } from '@apollo/client';
import { RemoveCircle, Router } from '@mui/icons-material';
import {
  Box,
  Chip,
  Paper,
  Tooltip,
  Typography,
  capitalize,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { DateTime } from 'luxon';
import { useCallback } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { useUpdateScheduledShow } from '~/api/scheduled-show';
import { ReadOnlyDataGrid } from '~/components/data-grid';
import { DeviceLogo, DeviceStatus } from '~/components/devices';
import { ConfirmDialog } from '~/components/dialogs/confirmation';
import { Link } from '~/components/link';
import { AuthorizedLink } from '~/components/links';
import { ListRowHeadingValue } from '~/components/list-row';
import { LoadingPane } from '~/components/loading-pane';
import { PageContainer } from '~/components/page-layout';
import { MetaItem, SettingsBody, SettingsData, SettingsHead } from '~/components/settings';
import { TableAction } from '~/components/table';
import { useAppContext } from '~/contexts';
import { useConfirmDialog } from '~/hooks/dialogs';
import { useLink } from '~/hooks/link';
import { assert } from '~/lib/assert';
import { formatDate } from '~/lib/datetime';
import { getScheduleDescription } from '~/lib/schedules';
import { ScheduledShowGetDocument } from '../queries/queries.generated';
import { ScheduleShowToolbar } from './components/toolbar';

export const ScheduleShowDetails = () => {
  const { currentNetwork } = useAppContext();

  const [confirmDelete, confirmDeleteProps] = useConfirmDialog();

  const link = useLink();

  const theme = useTheme();
  const isSmallAndUp = useMediaQuery(theme.breakpoints.up('sm'));

  const params = useParams<{ scheduledShowId: string }>();

  const scheduledShowId = parseInt(params.scheduledShowId ?? '');

  const [updateScheduledShow] = useUpdateScheduledShow();

  const { data, loading } = useQuery(ScheduledShowGetDocument, {
    variables: { networkId: currentNetwork.id, scheduledShowId },
  });

  const scheduledShow = data?.network?.scheduledShow;

  const devices = scheduledShow?.devices;

  const removeDevice = useCallback(
    async (deviceId: number) => {
      assert(scheduledShow != null);
      if (!(await confirmDelete())) return;
      await updateScheduledShow({
        variables: {
          id: scheduledShow.id,
          patch: {
            deviceIds: scheduledShow.devices
              .filter((device) => device.id !== deviceId)
              .map((x) => x.id),
          },
        },
      });
    },
    [confirmDelete, updateScheduledShow, scheduledShow],
  );

  if (!loading && !data?.network?.scheduledShow)
    return <Navigate to={link('/not-found')} replace />;

  return (
    <LoadingPane in={loading && !data} size={80} thickness={4}>
      {scheduledShow && (
        <>
          <ScheduleShowToolbar
            scheduledShow={scheduledShow}
            returnTo={{ pathname: '../..', search: location.search }}
          />
          <PageContainer>
            <Box>
              <SettingsHead>Details</SettingsHead>
              <SettingsBody>
                <SettingsData className="last no-space-between">
                  <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                    <MetaItem className="first">
                      <ListRowHeadingValue heading="name" value={scheduledShow.name} />
                    </MetaItem>
                    <MetaItem>
                      <ListRowHeadingValue
                        heading="show"
                        value={
                          <Link to={link(`/shows/${scheduledShow.show.id}/design`)}>
                            {scheduledShow.show.name}
                          </Link>
                        }
                      />
                    </MetaItem>
                    <MetaItem>
                      <ListRowHeadingValue
                        heading="Devices"
                        value={<Chip color="primary" label={scheduledShow.deviceCount || 0} />}
                      />
                    </MetaItem>
                    <MetaItem>
                      <ListRowHeadingValue
                        heading="Start Date"
                        value={
                          scheduledShow.schedule?.startDate
                            ? DateTime.fromISO(scheduledShow.schedule.startDate, {
                                zone: 'UTC',
                              }).toFormat('EEE, LLL dd, yyyy')
                            : ''
                        }
                      />
                    </MetaItem>
                    <MetaItem>
                      <ListRowHeadingValue
                        heading="Schedule"
                        value={
                          scheduledShow.schedule
                            ? getScheduleDescription(scheduledShow.schedule)
                            : ''
                        }
                      />
                    </MetaItem>
                    <MetaItem>
                      <ListRowHeadingValue
                        heading="Created"
                        value={formatDate(scheduledShow.createdAt)}
                      />
                    </MetaItem>
                    <MetaItem className="last">
                      <ListRowHeadingValue
                        heading="Updated"
                        value={formatDate(scheduledShow.updatedAt)}
                      />
                    </MetaItem>
                  </Box>
                </SettingsData>
              </SettingsBody>
            </Box>
            <Paper sx={{ mt: 2 }}>
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'space-between',
                  padding: theme.spacing(2),
                }}
              >
                <Box sx={{ alignItems: 'center', display: 'flex', flex: 1, gap: 2 }}>
                  <Box>
                    <Router sx={{ fontSize: '4em' }} color="primary" />
                  </Box>
                  <Box>
                    <Typography variant="h4">Devices</Typography>
                    <Typography variant="body1" sx={{ mt: 1 }}>
                      Devices assigned to this Scheduled Show.
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  backgroundColor: '#F8F8F8',
                  borderTop: '1px solid',
                  borderColor: '#dedcdc',
                  padding: theme.spacing(2),
                }}
              >
                <ReadOnlyDataGrid
                  autoHeight
                  columns={[
                    {
                      align: 'center',
                      display: 'flex',
                      headerAlign: 'center',
                      field: 'kind',
                      headerName: 'Type',
                      renderCell: ({ row }) => (
                        <Tooltip title={capitalize(row.kind)} arrow>
                          <Box>
                            <DeviceLogo kind={row.kind} />
                          </Box>
                        </Tooltip>
                      ),
                      sortable: false,
                      width: 50,
                    },
                    {
                      field: 'id',
                      headerName: 'ID',
                      sortable: false,
                      width: 65,
                    },
                    {
                      align: 'center',
                      headerAlign: 'center',
                      field: 'status',
                      headerName: '',
                      renderCell: ({ row }) => <DeviceStatus device={row} />,
                      sortable: false,
                      width: 65,
                    },
                    {
                      field: 'name',
                      flex: 1,
                      headerName: 'Name',
                      minWidth: isSmallAndUp ? 180 : 0,
                      renderCell: ({ row }) => (
                        <AuthorizedLink authorized={row.canUpdate.value} entity={row} />
                      ),
                      sortable: false,
                    },
                    {
                      field: 'group',
                      flex: 1,
                      headerName: 'Group',
                      minWidth: 120,
                      sortable: false,
                      renderCell: ({ row }) => (
                        <AuthorizedLink
                          authorized={row.group?.canUpdate.value}
                          entity={row.group}
                        />
                      ),
                    },
                    {
                      field: 'ipAddress',
                      flex: 1,
                      headerName: 'IP Address',
                      minWidth: 120,
                      sortable: false,
                      valueGetter: (_value, row) => row.ipAddress,
                    },
                    {
                      field: 'playerVersion',
                      flex: 1,
                      headerName: 'Version',
                      minWidth: 100,
                      sortable: false,
                      valueGetter: (_value, row) => row.playerVersion || '-',
                    },
                    {
                      align: 'right',
                      field: 'actions',
                      getActions: ({ row }) => [
                        <TableAction
                          color="error"
                          disabled={!row.canUpdate.value}
                          key={row.id}
                          title={
                            row.canUpdate.value
                              ? 'Remove'
                              : row.canUpdate.reasons?.fullMessages[0] ?? ''
                          }
                          Icon={RemoveCircle}
                          onClick={() => removeDevice(Number(row.id))}
                        />,
                      ],
                      headerName: '',
                      sortable: false,
                      type: 'actions',
                    },
                  ]}
                  disableRowSelectionOnClick
                  getRowClassName={() => 'readonly'}
                  rows={devices ?? []}
                  slots={{
                    columnResizeIcon: () => null,
                    noRowsOverlay: () => {
                      return (
                        <Box
                          height="100%"
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                        >
                          No devices are assigned to this schedule
                        </Box>
                      );
                    },
                  }}
                />
              </Box>
            </Paper>
          </PageContainer>
          <ConfirmDialog
            {...confirmDeleteProps}
            confirm="Remove"
            deleteConfirm
            prompt="Are you sure you want to remove this Device from this Scheduled Show?"
            title="Remove Device"
          />
        </>
      )}
    </LoadingPane>
  );
};
