import type { DatePickerProps, TimePickerProps } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import { useCallback, useState } from 'react';

export const useTimePicker = () => {
  type Props = TimePickerProps<DateTime>;

  const [value, setValue] = useState<DateTime | null>(DateTime.now());

  const [valid, setValid] = useState(true);

  const onChange = useCallback<NonNullable<Props['onChange']>>((value: DateTime | null) => {
    setValue(value);
  }, []);

  const onError = useCallback<NonNullable<Props['onError']>>(
    (error: unknown) => setValid(error === null),
    [],
  );

  const reset = useCallback((time: string | undefined | null) => {
    setValue(time != null ? DateTime.fromSQL(time) : null);
    setValid(true);
  }, []);

  return {
    props: {
      timeSteps: { minutes: 15 },
      skipDisabled: true,
      thresholdToRenderTimeInASingleColumn: 200,
      onChange,
      onError,
      value,
    },
    reset,
    value,
    valid,
  };
};

export const useDatePicker = () => {
  type Props = DatePickerProps<DateTime>;

  const [value, setValue] = useState<DateTime | null>(DateTime.now());

  const [valid, setValid] = useState(true);

  const onChange = useCallback<NonNullable<Props['onChange']>>((value: DateTime | null) => {
    setValue(value);
  }, []);

  const onError = useCallback<NonNullable<Props['onError']>>(
    (error: unknown) => setValid(error === null),
    [],
  );

  const reset = useCallback((date?: Date | null) => {
    setValue(
      date != null
        ? // the initial date comes from the RRule options, it's a midnight UTC date.
          // so we convert it to local time midnight on that data to avoid issues when saving
          DateTime.fromJSDate(date, { zone: 'UTC' }).setZone('local', { keepLocalTime: true })
        : DateTime.now(),
    );
    setValid(true);
  }, []);

  return {
    props: { format: 'EEEE, DDD', onChange, onError, value },
    reset,
    value,
    valid,
  };
};
