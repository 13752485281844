import { MenuItem } from '@mui/material';
import { useFormik } from 'formik';
import { useCallback, useMemo, useState } from 'react';
import { array, bool, number, object, string } from 'yup';
import { useUpdateDevice } from '~/api/devices';
import { useAssignPropertyGroup, useRemovePropertyGroup } from '~/api/property-groups';
import { DetailSelect, DetailSwitch, DetailTextField } from '~/components/forms/details';
import { DurationSlider } from '~/components/inputs';
import type { FormTableData } from '~/components/settings';
import { useAppContext } from '~/contexts';
import { DeviceNightlyBehavior, PropertyOwnerType } from '~/generated/graphql';
import { useConfirmDialog } from '~/hooks/dialogs';
import { duration } from '~/lib/validators';
import { useDevice } from '../context';

interface PropertyGroupFormData {
  id: number;
  name: string;
  propertyGroupId: number;
  fields: FormTableData[];
}

const validationSchema = object({
  eventStream: bool().required(),
  demo: bool().required(),
  deviceGroupId: number().nullable(),
  internal: bool().required(),
  screenshotInterval: duration({ min: { minutes: 1 }, max: { hours: 1 } }),
  nightlyBehavior: string().oneOf(['NONE', 'REBOOT', 'RESTART']).required(),
  overscan: number().required().min(50).max(110),
  realtime: bool().required(),
  timeZone: string().required(),
  propertyValuesAttributes: array(
    object({
      id: number().required(),
      value: string().nullable(),
    }),
  ),
});

export const useSettingsForm = () => {
  const { device, deviceGroups, propertyGroups, timeZones } = useDevice();
  const [updateDevice] = useUpdateDevice();
  const [assignPropertyGroup] = useAssignPropertyGroup();
  const [removePropertyGroup] = useRemovePropertyGroup();
  const [confirm, confirmDialogProps] = useConfirmDialog();
  const [showAddPropertyDialog, setShowAddPropertyDialog] = useState(false);
  const { currentUser } = useAppContext();

  const initialValues = useMemo(
    () => ({
      eventStream: device.eventStream,
      demo: device.demo,
      deviceGroupId: device.group?.id || -1,
      internal: device.internal,
      screenshotInterval: device.screenshotInterval ?? '',
      nightlyBehavior: device.nightlyBehavior,
      overscan: device.overscan || undefined,
      realtime: device.realtime,
      timeZone: device.timeZone,
      propertyValuesAttributes: device.propertyGroups
        .flatMap((x) => x.propertyValues)
        .map(({ id, propertyDefinition, value }) => ({
          id,
          value: value || propertyDefinition.defaultValue,
        })),
    }),
    [device],
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit: async (values) => {
      const newValues = validationSchema.cast(values);
      await updateDevice({
        variables: {
          deviceId: device.id,
          patch: {
            ...newValues,
            deviceGroupId: newValues.deviceGroupId === -1 ? null : newValues.deviceGroupId,
            screenshotInterval: newValues.screenshotInterval || null,
          },
        },
      });
    },
    validateOnMount: true,
    validationSchema,
  });

  const generalSettings: FormTableData[] = useMemo(() => {
    const settings = [
      {
        heading: 'Group',
        subHeading: 'The group this device belongs to',
        dataField: (
          <DetailSelect
            aria-label="group"
            disabled={formik.isSubmitting}
            name="deviceGroupId"
            value={formik.values.deviceGroupId}
            onChange={formik.handleChange}
          >
            <MenuItem value={-1}>None</MenuItem>
            {deviceGroups.map(({ id, name }) => (
              <MenuItem key={id} value={id}>
                {name}
              </MenuItem>
            ))}
          </DetailSelect>
        ),
      },
      {
        heading: 'Time Zone',
        subHeading: 'Time zone where this device is located',
        dataField: (
          <DetailSelect
            aria-label="time zone"
            disabled={formik.isSubmitting || !currentUser.admin}
            name="timeZone"
            value={formik.values.timeZone}
            onChange={formik.handleChange}
          >
            {timeZones.map(({ id, name }) => (
              <MenuItem key={id} value={id}>
                {name}
              </MenuItem>
            ))}
          </DetailSelect>
        ),
      },
      {
        heading: 'Automatic Screenshots',
        subHeading: 'Turn on/off automatic screenshots and set interval',
        dataField: (
          <DurationSlider
            max={{ hours: 1 }}
            min={{ minutes: 1 }}
            name="screenshotInterval"
            step={{ minutes: 1 }}
            value={formik.values.screenshotInterval}
            onChange={formik.handleChange}
          />
        ),
      },
      {
        heading: 'Overscan',
        subHeading:
          'Overscan for device, between 50 and 110.  Example: 87 for 87%, 100 for fullscreen (no scaling)',
        dataField: (
          <DetailTextField
            aria-label="overscan"
            disabled={formik.isSubmitting}
            name="overscan"
            value={formik.values.overscan}
            onChange={formik.handleChange}
            type="number"
          />
        ),
      },
      {
        heading: 'Nightly Behavior',
        subHeading: 'Turn on/off event log streaming',
        dataField: (
          <DetailSelect
            aria-label="nightly behavior"
            disabled={formik.isSubmitting || !currentUser.admin}
            name="nightlyBehavior"
            value={formik.values.nightlyBehavior}
            onChange={formik.handleChange}
          >
            {Object.entries(DeviceNightlyBehavior).map(([key, value]) => (
              <MenuItem key={key} value={value}>
                {key}
              </MenuItem>
            ))}
          </DetailSelect>
        ),
      },
      {
        heading: 'Event Stream',
        subHeading: 'Turn on/off event log streaming',
        dataField: (
          <DetailSwitch
            checked={formik.values.eventStream}
            color="primary"
            disabled={formik.isSubmitting}
            name="eventStream"
            onChange={formik.handleChange}
          />
        ),
      },
      {
        heading: 'Real Time',
        subHeading: 'Turn on/off real time mode',
        dataField: (
          <DetailSwitch
            checked={formik.values.realtime}
            color="primary"
            disabled={formik.isSubmitting}
            name="realtime"
            onChange={formik.handleChange}
          />
        ),
      },
    ];

    if (currentUser.admin) {
      settings.push(
        {
          heading: 'Internal',
          subHeading: 'Turn on/off Internal mode',
          dataField: (
            <DetailSwitch
              checked={formik.values.internal}
              color="primary"
              disabled={formik.isSubmitting || !currentUser.admin}
              name="internal"
              onChange={formik.handleChange}
            />
          ),
        },
        {
          heading: 'Demo',
          subHeading: 'Turn on/off Demo mode',
          dataField: (
            <DetailSwitch
              checked={formik.values.demo}
              color="primary"
              disabled={formik.isSubmitting || !currentUser.admin}
              name="demo"
              onChange={formik.handleChange}
            />
          ),
        },
      );
    }

    return settings;
  }, [deviceGroups, formik, timeZones, currentUser.admin]);

  const getPropertyGroupValue = useCallback(
    (id: number) => formik.values.propertyValuesAttributes.find((x) => x.id === id)?.value,
    [formik],
  );

  const setPropertyGroupValue = useCallback(
    (id: number, value: string) => {
      const index = formik.values.propertyValuesAttributes.findIndex((x) => x.id === id);
      void formik.setFieldValue(`propertyValuesAttributes.${index}`, {
        id,
        value: String(value),
      });
    },
    [formik],
  );

  const propertyGroupSettings: PropertyGroupFormData[] = useMemo(
    () =>
      device.propertyGroups.map((pg) => ({
        id: pg.id,
        name: pg.name,
        propertyGroupId: pg.propertyGroupId,
        fields:
          pg.propertyValues.length === 0
            ? [{ heading: `No ${pg.name} settings` }]
            : pg.propertyValues.map(({ id, propertyDefinition }) => ({
                heading: propertyDefinition.label,
                subHeading: propertyDefinition.helpText,
                dataField:
                  propertyDefinition.kind === 'boolean' ? (
                    <DetailSwitch
                      checked={getPropertyGroupValue(id) === 'true'}
                      color="primary"
                      disabled={formik.isSubmitting}
                      onChange={(event) => setPropertyGroupValue(id, String(event.target.checked))}
                    />
                  ) : propertyDefinition.allowedValues != null ? (
                    <DetailSelect
                      onChange={(event) => setPropertyGroupValue(id, String(event.target.value))}
                      value={getPropertyGroupValue(id)}
                    >
                      <MenuItem value="">(None)</MenuItem>
                      {propertyDefinition.allowedValues.map(({ label, value }, index) => (
                        <MenuItem key={index} value={value}>
                          {label}
                        </MenuItem>
                      ))}
                    </DetailSelect>
                  ) : (
                    <DetailTextField
                      onChange={(event) => setPropertyGroupValue(id, String(event.target.value))}
                      value={getPropertyGroupValue(id)}
                    />
                  ),
              })),
      })),
    [device, formik, getPropertyGroupValue, setPropertyGroupValue],
  );

  const addPropertyGroup = useCallback(
    async ({ id }: { id: string }) => {
      const propertyGroupId = Number(id);
      const result = await assignPropertyGroup({
        variables: {
          ownerId: device.id,
          ownerType: PropertyOwnerType.Device,
          propertyGroupId,
        },
      });
      if (!result.errors?.length) {
        setShowAddPropertyDialog(false);
      }
    },
    [assignPropertyGroup, device],
  );

  const deletePropertyGroup = useCallback(
    async (propertyGroup: { propertyGroupId: number }) => {
      if (!(await confirm())) return;
      await removePropertyGroup({
        variables: {
          ownerId: device.id,
          ownerType: PropertyOwnerType.Device,
          propertyGroupId: propertyGroup.propertyGroupId,
        },
      });
    },
    [confirm, device, removePropertyGroup],
  );

  const filteredPropertyGroups = useMemo(
    () =>
      propertyGroups.filter(
        ({ id }) =>
          !device.propertyGroups.flatMap(({ propertyGroupId }) => propertyGroupId).includes(id),
      ),
    [device, propertyGroups],
  );

  return {
    addPropertyGroup,
    confirmDialogProps,
    deletePropertyGroup,
    filteredPropertyGroups,
    formik,
    generalSettings,
    propertyGroupSettings,
    setShowAddPropertyDialog,
    showAddPropertyDialog,
  };
};
