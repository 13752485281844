import { useQuery } from '@apollo/client';
import { AddCircle, CheckBox, CheckBoxOutlineBlank, Delete } from '@mui/icons-material';
import {
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ToggleButton,
  ToggleButtonGroup,
  styled,
} from '@mui/material';
import { Fragment, useMemo, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { useAppContext } from '~/contexts';
import type { ChannelGuideFormOutletProps } from '../components/ChannelGuideForm';
import { ChannelGuideChannelsDocument } from './ChannelGuideChannelsPanel.generated';

const FilterToggleGroup = styled(ToggleButtonGroup)({
  flexDirection: 'row',
  display: 'flex',
  justifyContent: 'center',
});

const FilterToggleButton = styled(ToggleButton)(({ theme }) => ({
  textTransform: 'capitalize',
  width: '50%',
  gap: theme.spacing(0.5),
  color: 'black',
  '&.MuiToggleButton-root.Mui-selected:not(.Mui-disabled)': {
    color: 'black',
    backgroundColor: 'rgb(191, 221, 255, .5)',
  },
  '&:hover': {
    backgroundColor: 'rgb(191, 221, 255, .25)',
  },
}));

const Root = styled('div')(({ theme }) => ({
  padding: theme.spacing(2),
  '.MuiListItem-root': {
    alignItems: 'center',
    fontSize: 18,
    gap: theme.spacing(2),
    margin: theme.spacing(1, 0),
    padding: theme.spacing(0),
  },

  '.MuiListItemIcon-root': {
    flexBasis: '4rem',
    maxHeight: '4rem',
    '& img': {
      objectFit: 'contain',
      objectPosition: 'center',
      width: '100%',
      borderRadius: 4,
    },
  },

  '.MuiIconButton-root': {
    color: '#174265',
  },
}));

const partition = <T,>(
  arr: readonly T[],
  predicate: (value: T, index: number, array: readonly T[]) => unknown,
): readonly [readonly T[], readonly T[]] => {
  const yay = [] as T[];
  const nay = [] as T[];
  arr.forEach((value, index, array) => (predicate(value, index, array) ? yay : nay).push(value));
  return [yay, nay];
};

type ToggleValue = 'available' | 'selected';

export const ChannelGuideChannelsPanel = () => {
  const { currentNetwork } = useAppContext();
  const { data } = useQuery(ChannelGuideChannelsDocument, {
    variables: { networkId: currentNetwork.id },
  });

  const { formik } = useOutletContext<ChannelGuideFormOutletProps>();

  const [toggleValue, setToggleValue] = useState<ToggleValue>('selected');

  const channels = useMemo(() => {
    if (data?.network?.channels == null) return { available: [], selected: [] };

    const selectedIds = new Set(formik.values.channelIds);

    const [selected, available] = partition(data.network.channels, (channel) =>
      selectedIds.has(channel.id),
    );

    return { available, selected };
  }, [data?.network?.channels, formik.values.channelIds]);

  return (
    <Root>
      <FilterToggleGroup
        exclusive
        fullWidth
        onChange={(_, value: ToggleValue | null) => value && setToggleValue(value)}
        size="small"
        value={toggleValue}
      >
        <FilterToggleButton value="selected">
          <CheckBox fontSize="small" />
          Selected
        </FilterToggleButton>
        <FilterToggleButton value="available">
          <CheckBoxOutlineBlank fontSize="small" />
          Available
        </FilterToggleButton>
      </FilterToggleGroup>

      <List>
        {channels[toggleValue].map((channel) => {
          const Icon = toggleValue === 'selected' ? Delete : AddCircle;
          const onClick = () =>
            formik.setFieldValue(
              'channelIds',
              toggleValue === 'selected'
                ? formik.values.channelIds.filter((id) => id !== channel.id)
                : [...formik.values.channelIds, channel.id],
            );

          return (
            <Fragment key={channel.id}>
              <ListItem>
                <ListItemIcon>
                  <img crossOrigin="anonymous" src={channel.thumbnailFile.uri}></img>
                </ListItemIcon>

                <ListItemText primary={channel.name} secondary={channel.number}></ListItemText>

                <IconButton onClick={onClick}>
                  <Icon />
                </IconButton>
              </ListItem>
              <Divider />
            </Fragment>
          );
        })}
      </List>
    </Root>
  );
};
