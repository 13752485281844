import { useLazyQuery } from '@apollo/client';
import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import { useFormik } from 'formik';
import { useCallback, useEffect, useState, type ComponentProps } from 'react';
import { number, object, string } from 'yup';
import { DialogForm } from '~/components/dialogs/components';
import { useAppContext } from '~/contexts';
import { type Schedule } from '~/generated/graphql';
import {
  AvailableShowsDocument,
  type Device__AvailableShow as Show,
} from '~/pages/devices/queries/available-shows.generated';
import { type ScheduleOptions } from './lib/schedule-options';
import { ScheduleFields } from './schedule-fields';

export type ScheduledShow = {
  id: number;
  name: string;
  schedule?: Schedule | null | undefined;
  show: Show;
};

export interface ScheduledShowFormValues {
  name?: string;
  showId?: string | number;
}

export const scheduledShowValidationSchema = object({
  name: string().optional(),
  showId: number().required(),
});

export const useScheduledShowFormik = (
  initialValues: ScheduledShowFormValues,
  onSubmit: (values: ScheduledShowFormValues) => Promise<void>,
) => {
  return useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit: async (values, { resetForm, setValues }) => {
      await onSubmit(values);
      resetForm();
      setValues(initialValues);
    },
    validateOnBlur: true,
    validateOnMount: true,
    validationSchema: scheduledShowValidationSchema,
  });
};

export interface ScheduledShowFormProps extends Omit<ComponentProps<'form'>, 'onSubmit'> {
  formik: ReturnType<typeof useScheduledShowFormik>;
  scheduleOptions: ScheduleOptions;
  show?: Show;
}

export const ScheduledShowForm = ({
  formik,
  scheduleOptions,
  show,
  ...props
}: ScheduledShowFormProps) => {
  const { currentNetwork } = useAppContext();

  const [selectedShow, setSelectedShow] = useState<Show | undefined>(show);

  const [showsLoading, setShowsLoading] = useState(false);

  const [showsOpen, setShowsOpen] = useState(false);

  const [shows, setShows] = useState<readonly Show[]>([]);

  const [showsQuery] = useLazyQuery(AvailableShowsDocument);

  useEffect(() => {
    if (!showsOpen) {
      setShows([]);
    }
  }, [showsOpen]);

  const handleShowOpen = useCallback(async () => {
    setShowsOpen(true);
    setShowsLoading(true);
    const { data } = await showsQuery({
      variables: {
        currentNetworkId: currentNetwork.id,
      },
    });
    setShowsLoading(false);
    setShows(data?.network?.shows.nodes ?? []);
  }, [currentNetwork, showsQuery]);

  const handleShowChange = useCallback(
    async (_event: React.SyntheticEvent, newValue: Show | null) => {
      if (!newValue) return;
      setSelectedShow(newValue);
      await formik.setFieldValue('showId', newValue.id);
    },
    [formik],
  );

  return (
    <DialogForm onSubmit={formik.handleSubmit} {...props}>
      <label>
        <span className={'label'}>Name</span>
        <TextField
          fullWidth
          helperText="Optionally, enter a custom name for this Schedule"
          name="name"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.name}
          focused
          autoFocus
        />
      </label>
      <label>
        <span className={`label required`}>Show</span>
        <Autocomplete
          disableClearable
          fullWidth
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          loading={showsLoading}
          onChange={handleShowChange}
          open={showsOpen}
          onOpen={handleShowOpen}
          onClose={() => {
            setShowsOpen(false);
          }}
          options={shows}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Select a Show"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {showsLoading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          sx={{
            display: 'inline-flex', // hackeration so label is centered since it's set to baseline
          }}
          value={selectedShow}
        />
      </label>
      <ScheduleFields scheduleOptions={scheduleOptions} />
    </DialogForm>
  );
};
