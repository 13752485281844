import {
  LiveTv,
  MonitorHeart,
  MoreHoriz,
  RocketLaunch,
  Send,
  VisibilityOff,
} from '@mui/icons-material';
import { Box, Chip, Tooltip, useMediaQuery, useTheme } from '@mui/material';
import type { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { ActionButton, ActionButtonLink } from '~/components/button';
import { DeviceLogo, DeviceStatus, DeviceVersion } from '~/components/devices';
import { AuthorizedLink } from '~/components/links';
import { capitalize, pluralize } from '~/lib/string';
import type { DeviceList__Device as Device } from '../../queries/list.generated';
import { Menu } from '../components';

export interface UseColumnsProps {
  onSendCommand: (device: Device[]) => void;
}

export const useColumns = ({ onSendCommand }: UseColumnsProps): GridColDef<Device>[] => {
  const theme = useTheme();
  const isSmallAndUp = useMediaQuery(theme.breakpoints.up('sm'));

  const location = useLocation();

  return useMemo(
    () => [
      {
        align: 'center',
        display: 'flex',
        headerAlign: 'center',
        field: 'kind',
        headerName: 'Type',
        renderCell: ({ row }) => (
          <Tooltip title={capitalize(row.kind)} arrow>
            <Box display="flex">
              <DeviceLogo kind={row.kind} />
            </Box>
          </Tooltip>
        ),
        sortable: false,
        width: 50,
        resizable: false,
      },
      {
        field: 'channels',
        headerName: '',
        sortable: false,
        width: 50,
        resizable: false,
        renderCell: ({ row }) => {
          const channelCount = row.show?.channelGuide?.channelCount ?? 0;
          return (
            <Tooltip
              title={
                channelCount > 0
                  ? `${channelCount} IPTV ${pluralize('channel', channelCount)}  available`
                  : 'No IPTV channels available'
              }
            >
              <Box display="flex">
                <LiveTv
                  style={{
                    color: channelCount > 0 ? theme.palette.primary.dark : theme.palette.grey[300],
                  }}
                />
              </Box>
            </Tooltip>
          );
        },
      },
      {
        field: 'id',
        headerName: 'ID',
        width: 65,
        resizable: false,
      },
      {
        align: 'center',
        headerAlign: 'center',
        field: 'status',
        headerName: '',
        renderCell: ({ row }) => <DeviceStatus device={row} />,
        sortable: false,
        width: 65,
        resizable: false,
      },
      {
        field: 'name',
        flex: 1,
        headerName: 'Name',
        minWidth: isSmallAndUp ? 180 : 0,
      },
      {
        field: 'group',
        flex: 1,
        headerName: 'Group',
        minWidth: 100,
        resizable: false,
        sortable: false,
        renderCell: ({ row }: GridRenderCellParams<Device>) => (
          <AuthorizedLink authorized={row.group?.canUpdate.value} entity={row.group} />
        ),
      },
      {
        field: 'show',
        flex: 1,
        headerName: 'Now Playing',
        minWidth: 150,
        resizable: false,
        sortable: false,
        renderCell: ({ row }: GridRenderCellParams<Device>) => (
          <AuthorizedLink authorized={row.show?.canUpdate.value} entity={row.show} />
        ),
      },
      {
        field: 'ipAddress',
        headerName: 'IP Address',
        minWidth: 120,
        resizable: false,
        sortable: false,
      },
      {
        field: 'playerVersion',
        headerName: 'Version',
        minWidth: 150,
        renderCell: ({ row }) => <DeviceVersion device={row} />,
        resizable: false,
        sortable: true,
      },
      {
        field: 'internal',
        headerName: '',
        minWidth: 120,
        resizable: false,
        sortable: false,
        renderCell: ({ row }) => {
          return (
            <>
              {row.internal === true && (
                <Chip
                  icon={<VisibilityOff />}
                  label="Internal"
                  color="primary"
                  sx={{ marginRight: '8px' }}
                />
              )}
              {row.demo === true && (
                <Chip
                  icon={<RocketLaunch />}
                  label="Demo"
                  color="info"
                  sx={{ backgroundColor: 'rgb(156, 39, 176)' }}
                />
              )}
            </>
          );
        },
      },
      {
        field: 'actions',
        type: 'actions',
        minWidth: isSmallAndUp ? 150 : 0,
        resizable: false,
        getActions: ({ row }) => {
          return [
            <ActionButton
              key={`send-command-${row.id}`}
              title="Send Command"
              Icon={Send}
              onClick={() => onSendCommand([row])}
            />,
            <ActionButtonLink
              key={`dashboard-${row.id}`}
              title="Dashboard"
              Icon={MonitorHeart}
              to={{ pathname: `${row.id}/details`, search: location.search }}
            />,
            <Menu
              key={`more-action-${row.id}`}
              device={row}
              Icon={MoreHoriz}
              buttonType="action"
            />,
          ];
        },
        sortable: false,
      },
    ],
    [isSmallAndUp, location.search, onSendCommand, theme],
  );
};
