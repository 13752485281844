import { Button, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import type { ReactNode } from 'react';
import type { LinkProps } from '~/components/link';
import { Link } from '~/components/link';

const useStyles = makeStyles((theme) => ({
  link: {
    background: '#ffffff',
    padding: theme.spacing(2),
    border: '1px solid #e6e6e6',
    borderRadius: '4px',
    '&:hover': {
      background: '#f8f8f8',
      cursor: 'pointer',
    },
    display: 'flex',
    flexDirection: 'column',
  },
  icon: {
    color: theme.palette.primary.main,
    textAlign: 'center',
    '& svg': {
      fontSize: '50px',
    },
  },
  linkBtn: {
    background: '#ffffff',
    padding: theme.spacing(2),
    width: '100%',
    border: '1px solid #e6e6e6',
    borderRadius: '4px',
    '&:hover': {
      background: '#f8f8f8',
      cursor: 'pointer',
    },
    '&.Mui-disabled': {
      backgroundColor: '#f8f8f8',
    },
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    textAlign: 'center',
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '28px',
    color: '#000000',
  },
  caption: {
    paddingTop: '2px',
    textAlign: 'center',
    fontSize: '14px',
    fontWeight: '400',
    color: '#768599',
  },
}));

const Wrapper = (props: { children: ReactNode }) => (
  <Grid item xs={12} sm={6} md={4} lg={4} xl={4} {...props} />
);

export interface ShortcutCardProps {
  icon: ReactNode;
  title: string;
  caption: string;
  onClick?: () => void;
  disabled?: boolean;
  href?: string;
}

export const ShortcutCard = (props: ShortcutCardProps) => {
  const { icon, title, caption, onClick, disabled = false, href } = props;

  const classes = useStyles();

  return (
    <Wrapper>
      <Button className={classes.linkBtn} onClick={onClick} disabled={disabled} href={href}>
        <div className={classes.icon}>{icon}</div>
        <div className={classes.title}>{title}</div>
        <div className={classes.caption}>{caption}</div>
      </Button>
    </Wrapper>
  );
};

export interface ShortcutCardLinkProps extends LinkProps {
  icon: ReactNode;
  title: string;
  caption: string;
}

export const ShortcutCardLink = (props: ShortcutCardLinkProps) => {
  const { icon, title, caption, ...linkProps } = props;

  const classes = useStyles();

  return (
    <Wrapper>
      <Link underline="none" className={classes.link} {...linkProps}>
        <div className={classes.icon}>{icon}</div>
        <div className={classes.title}>{title}</div>
        <div className={classes.caption}>{caption}</div>
      </Link>
    </Wrapper>
  );
};
