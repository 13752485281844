import { DeviceHub, RemoveCircle } from '@mui/icons-material';
import { Chip, Tooltip, useMediaQuery, useTheme } from '@mui/material';
import {
  type DataGridProProps as DataGridProps,
  type GridColDef,
  type GridColumnVisibilityModel,
} from '@mui/x-data-grid-pro';
import { useEffect, useMemo, useState } from 'react';
import { useRemoveFromRole } from '~/api/roles';
import { ActionButton } from '~/components/button';
import { SeparatedDataGrid } from '~/components/data-grid';
import { DeviceLogo } from '~/components/devices';
import { ConfirmDialog } from '~/components/dialogs/confirmation';
import { EmptyState } from '~/components/empty-state';
import { AuthorizedLink } from '~/components/links';
import { ListCheckbox } from '~/components/list-row';
import { useConfirmDialog } from '~/hooks/dialogs';
import ActivateDeviceIllustration from '~/images/illustrations/activate-device.svg';
import { capitalize, pluralize } from '~/lib/string';
import type {
  RoleDeviceList__DeviceGroupRule,
  RoleDeviceList__DeviceRule,
  RoleDeviceList__Role,
} from '../RoleDeviceList.generated';

const NoRowsOverlay = () => (
  <EmptyState
    illustration={ActivateDeviceIllustration}
    description="Adding Devices to this Role allows Users to access them."
    header="Add Devices to this Role"
  />
);

type Row = RoleDeviceList__DeviceRule | RoleDeviceList__DeviceGroupRule;

interface RemoveButtonProps {
  role: RoleDeviceList__Role;
  row: Row;
}

const RemoveButton = ({ role, row }: RemoveButtonProps) => {
  const key = row.__typename === 'DeviceRule' ? 'deviceRuleIds' : 'deviceGroupRuleIds';
  const [removeFromRole, { called, reset }] = useRemoveFromRole({
    variables: { roleId: role.id, [key]: row.id },
  });

  const [confirmDelete, confirmDeleteProps] = useConfirmDialog();

  const onClick = async () => {
    if (!(await confirmDelete())) return;
    await removeFromRole();
    reset();
  };

  const prompt =
    row.__typename === 'DeviceRule'
      ? `Removing ${row.device.name} from the ${role.name} role will remove access to the device.`
      : `Removing the ${row.deviceGroup.name} group from the ${
          role.name
        } role will remove access to ${row.deviceGroup.deviceCount} ${pluralize(
          'device',
          row.deviceGroup.deviceCount,
        )}.`;

  const what = row.__typename === 'DeviceRule' ? 'Device' : 'Device Group';

  return (
    <>
      <ActionButton
        color="error"
        disabled={called}
        Icon={RemoveCircle}
        onClick={onClick}
        title="Remove"
      />

      <ConfirmDialog
        {...confirmDeleteProps}
        confirm={`Remove ${what}`}
        deleteConfirm
        prompt={prompt}
        title={`Remove ${what}`}
      />
    </>
  );
};

const useColumns = (role: RoleDeviceList__Role): GridColDef<Row>[] =>
  useMemo(
    () => [
      {
        align: 'center',
        headerAlign: 'center',
        field: 'type',
        renderCell: ({ row }) =>
          row.__typename === 'DeviceRule' ? (
            <Tooltip title={capitalize(row.device.kind)} arrow>
              <span>
                <DeviceLogo kind={row.device.kind} />
              </span>
            </Tooltip>
          ) : (
            <Tooltip title="Device Group" arrow>
              <DeviceHub color="primary" />
            </Tooltip>
          ),
      },
      {
        field: 'name',
        flex: 0.5,
        renderCell: ({ row }) => (
          <AuthorizedLink
            authorized={
              row.__typename === 'DeviceRule'
                ? row.device.canUpdate.value
                : row.deviceGroup.canUpdate.value
            }
            entity={row.__typename === 'DeviceRule' ? row.device : row.deviceGroup}
          />
        ),
      },
      {
        field: 'devices',
        align: 'center',
        headerAlign: 'center',
        renderCell: ({ row }) =>
          row.__typename === 'DeviceGroupRule' ? (
            <Chip color="primary" label={row.deviceGroup.deviceCount} />
          ) : (
            '--'
          ),
      },
      {
        field: 'actions',
        type: 'actions',
        getActions: ({ row }) => [<RemoveButton key={0} role={role} row={row} />],
      },
    ],
    [role],
  );

export interface DeviceTableProps extends Omit<DataGridProps<Row>, 'columns'> {
  loading: boolean;
  role: RoleDeviceList__Role;
}

export const DeviceTable = ({ loading, role, ...props }: DeviceTableProps) => {
  const columns = useColumns(role);

  const [showColumns, setShowColumns] = useState<GridColumnVisibilityModel>({});

  const theme = useTheme();
  const isSmallAndDown = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    setShowColumns((x) => ({
      ...x,
      type: !isSmallAndDown,
      devices: !isSmallAndDown,
    }));
  }, [isSmallAndDown]);

  return (
    <SeparatedDataGrid
      columns={columns}
      columnVisibilityModel={showColumns}
      loading={loading}
      getRowId={(row) => `${row.__typename}:${row.id}`}
      slots={{
        baseCheckbox: ListCheckbox,
        columnResizeIcon: () => null,
        noRowsOverlay: NoRowsOverlay,
      }}
      {...props}
    />
  );
};
