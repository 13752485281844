import { Edit, MoreHoriz } from '@mui/icons-material';
import { useMediaQuery, useTheme } from '@mui/material';
import type { GridColDef } from '@mui/x-data-grid-pro';
import { useMemo } from 'react';
import { ActionButton } from '~/components/button';
import { ThumbnailContainer } from '~/pages/channel-guides/lib';
import { ChannelMenu } from '../components';
import type { ChannelTableProps__Channel as Channel } from '../components/ChannelTable.generated';
import { formatEncryption } from './formatters';

export interface UseColumnsProps {
  onEditChannel: (channel: Channel) => void;
}

export const useColumns = ({ onEditChannel }: UseColumnsProps): GridColDef<Channel>[] => {
  const theme = useTheme();
  const isSmallAndUp = useMediaQuery(theme.breakpoints.up('sm'));

  return useMemo(
    () => [
      {
        align: 'center',
        field: 'thumbnailFile',
        headerName: 'Thumbnail',
        minWidth: 200,
        renderCell: ({ row: channel, value: thumbnailFile }) => {
          return thumbnailFile ? (
            <ThumbnailContainer sx={{ backgroundColor: channel.backgroundColor }}>
              <img
                crossOrigin="anonymous"
                src={thumbnailFile.uri}
                style={{ margin: '0.5rem', height: '100%' }}
              />
            </ThumbnailContainer>
          ) : null;
        },
      } satisfies GridColDef<Channel, Channel['thumbnailFile']>,
      {
        field: 'name',
        flex: 1,
      },
      {
        field: 'number',
        flex: 1,
      },
      {
        field: 'encryption',
        flex: 1,
        valueFormatter: ({ value }) => formatEncryption(value),
      } satisfies GridColDef<Channel, Channel['encryption'], string>,
      {
        field: 'actions',
        type: 'actions',
        minWidth: isSmallAndUp ? 150 : 0,
        getActions: ({ row: channel }) => {
          return [
            <ActionButton
              key={`edit-${channel.id}`}
              title="Edit"
              Icon={Edit}
              onClick={() => onEditChannel(channel)}
            />,
            <ChannelMenu
              key={`more-action-${channel.id}`}
              channel={channel}
              Icon={MoreHoriz}
              buttonType="action"
            />,
          ];
        },
      },
    ],
    [isSmallAndUp, onEditChannel],
  );
};
