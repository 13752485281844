import { createContext, useContext, type ReactNode } from 'react';
import { assert } from '~/lib/assert';
import {
  type DeviceSettings__Device as Device,
  type DeviceSettings__DeviceGroup as DeviceGroup,
  type DeviceSettings__TopPropertyGroup as PropertyGroup,
  type DeviceSettings__TopTimeZone as TimeZone,
} from '../../queries/queries.generated';

export interface DeviceContextType {
  availableShows: ReadonlyArray<{
    id: number;
    name: string;
  }>;
  channelCount: number;
  device: Device;
  deviceGroups: ReadonlyArray<DeviceGroup>;
  propertyGroups: ReadonlyArray<PropertyGroup>;
  timeZones: ReadonlyArray<TimeZone>;
}

export const DeviceContext = createContext<DeviceContextType | undefined>(undefined);

export const useDevice = () => {
  const context = useContext(DeviceContext);
  assert(context !== undefined, 'useDevice must be used within an DeviceProvider');
  return context;
};

export interface DeviceProviderProps extends DeviceContextType {
  children: ReactNode;
}

export const DeviceProvider = ({
  availableShows,
  channelCount,
  children,
  device,
  deviceGroups,
  propertyGroups,
  timeZones,
}: DeviceProviderProps) => (
  <DeviceContext.Provider
    value={{ availableShows, channelCount, device, deviceGroups, propertyGroups, timeZones }}
  >
    {children}
  </DeviceContext.Provider>
);
