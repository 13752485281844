import { CheckCircle, Error, Warning } from '@mui/icons-material';
import { Tooltip, Typography } from '@mui/material';
import { format } from 'timeago.js';
import { ago, compareInterval, formatDate } from '~/lib/datetime';
import { ValueWrapper } from '~/pages/devices/show/lib/index';
import type { DeviceStatus__Device as Device } from './device-status.generated';

/* GraphQL */ `#graphql
  fragment DeviceStatus__Device on Device {
    activationChangedAt
    active
    id
    timeSinceUpdate {
      ...Interval
    }
  }
`;

export const StatusIcon = {
  success: <CheckCircle sx={{ color: 'success.main' }} />,
  error: <Error sx={{ color: 'error.main' }} />,
  warning: <Warning sx={{ color: 'warning.main' }} />,
};

export const activationStatus = (device: Device) => {
  const status = device.active ? 'success' : 'error';
  const title = device.active ? 'Activated' : 'Deactivated';
  const activationChanged = formatDate(device.activationChangedAt);
  const caption = `${title} at ${activationChanged as string}`;
  return { status, title, caption } as const;
};

export const connectionStatus = ({ timeSinceUpdate }: Device) => {
  if (timeSinceUpdate == null) {
    return {
      status: 'error' as const,
      title: 'Never Connected',
      caption: 'Never Connected',
    };
  }

  const result =
    compareInterval(timeSinceUpdate, { minutes: 10 }) > 0
      ? {
          status: 'error' as const,
          title: 'Offline',
        }
      : compareInterval(timeSinceUpdate, { minutes: 2 }) > 0
      ? {
          status: 'warning' as const,
          title: 'Recently Connected',
        }
      : {
          status: 'success' as const,
          title: 'Online',
        };
  return { ...result, caption: `Last checked in ${format(ago(timeSinceUpdate))}` };
};

export interface DeviceStatusProps {
  device: Device;
  displayText?: boolean;
}

export const DeviceStatus = ({ device, displayText }: DeviceStatusProps) => {
  const { caption, status, title } = device.active
    ? connectionStatus(device)
    : activationStatus(device);
  return (
    <Tooltip title={caption}>
      <ValueWrapper>
        {StatusIcon[status]}
        {displayText && (
          <Typography variant="h6" sx={{ color: `${status}.main`, mt: '2px' }}>
            {title}
          </Typography>
        )}
      </ValueWrapper>
    </Tooltip>
  );
};
