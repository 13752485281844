import { Android } from '@mui/icons-material';
import { DeviceKind } from '~/generated/graphql';
import brightsign from '~/images/brightsign.svg';
import linux from '~/images/linux.svg';
import samsung from '~/images/samsung.png';
import webos from '~/images/webos.png';

export interface DeviceLogoProps {
  kind: DeviceKind | undefined;
}

export const DeviceLogo = ({ kind }: DeviceLogoProps) =>
  kind === DeviceKind.Android ? (
    <Android sx={{ color: '#000' }} />
  ) : kind === DeviceKind.Linux ? (
    <img src={linux} style={{ height: '1.6em' }} />
  ) : kind === DeviceKind.Brightsign ? (
    <img src={brightsign} style={{ height: '1em' }} />
  ) : kind === DeviceKind.Webos ? (
    <img src={webos} style={{ height: '1em' }} />
  ) : kind === DeviceKind.Samsung ? (
    <img src={samsung} style={{ height: '2.5em' }} />
  ) : null;
